<template class="c-ToolPanelColumns">
    <v-card class="c-ToolPanelColumns_1 mx-auto w-100 pa-0" style="width: 100%; display: flex; flex-direction: row; max-width: 650px;" v-if="isvisible" :elevation="0">
        <v-card class="mx-auto w-100 pa-0" style="overflow: auto; width: 100%; flex-grow: 1; display: flex; flex-direction: column;" :elevation="0" color="grey lighten-5">
            <v-card-subtitle class="mt-0">
                Select the columns to include in the grid.
            </v-card-subtitle>

            <v-card-subtitle class="subtitle-1">
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey lighten-5">
                            Selected columns ({{ selected_columns.length }} selected)
                        </v-expansion-panel-header>

                        <v-expansion-panel-content color="grey lighten-5">
                            <v-chip-group active-class="primary--text" column class="pl-2">
                                <v-chip close outlined close-icon="mdi-close" v-for="item in selected_columns" :key="item.id" @click:close="setcolumnvisible({ColId: item.colId}, false)" color="primary">
                                    <template>
                                        {{ item.headerName }}
                                    </template>
                                </v-chip>
                            </v-chip-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-subtitle>

            <v-card-title class="subtitle-1" v-if="false">
                Selected columns: <span v-if="selected_columns.length == 0" class="ml-3 mt-2 mb-2">None selected</span>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn plain v-bind="attrs" v-on="on">{{ selected_columns.length }} selected<v-icon>mdi-menu-down</v-icon></v-btn>
                    </template>
                    <v-card style="max-width: 550px;" :elevation="0">
                        <v-chip-group active-class="primary--text" column class="pl-2">
                            <v-chip close outlined close-icon="mdi-close" v-for="item in selected_columns" :key="item.colId" @click:close="setcolumnvisible({ColId: item.colId}, false)" color="primary">
                                <template>
                                    {{ item.headerName }}
                                </template>
                            </v-chip>
                        </v-chip-group>
                    </v-card>
                </v-menu>
            </v-card-title>

            <v-text-field outlined dense hide-details clearable label="Search" v-model="search" class="mb-4">
                <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                </template>
                <template v-slot:append-outer>

                    <v-menu offset-y :close-on-content-click="false" :close-on-click="false" v-model="filter_menuopen">
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge color="primary" dot offset-y="25" offset-x="10" :value="!all_groupsSelected">
                                <v-btn icon small v-bind="attrs" v-on="on" class="ma-0">
                                    <v-icon>mdi-filter</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-card @mouseleave="filter_menuopen = false">
                            <MetricTypeFilter :debugMode="debugMode"
                                              :datatypes="columnDataTypes"
                                              :initial_datatypes_selection="columnDataTypes_selection"
                                              @datatypes_changed="(values) => columnDataTypes_selection = values"
                                              :categories="columnCategories"
                                              :initial_category_selection="columnCategory_selection"
                                              @categories_changed="(values) => columnCategory_selection = values"
                                              
                                              @anyfilters="(value) => anyFilters = value">

                            </MetricTypeFilter>
                        </v-card>
                    </v-menu>

                </template>
            </v-text-field>

            <div v-show="!search" v-for="(cat,k) in filterCategories" :key="k">
                <v-card-subtitle v-if="isCategoryVisible(cat)" style="border-bottom: 2px solid silver; color: #9d9d9d;">{{ filterCategoryPrefix(cat).toUpperCase() }}</v-card-subtitle>

                <ColumnItemList v-if="isCategoryVisible(cat)"
                                :ReadOnly="ReadOnly"
                                :items="getColumnsByCategory(cat)"
                                :columnLookup="column_lookup"
                                :column_default_visibility="column_default_visibility"
                                :showHelp="showHelp"
                                :addColumn="addColumn"
                                :removeColumn="removeColumn"
                                :getColumnHelp="getColumnHelp"
                                @setvisible="setcolumnvisible"
                                @setdefaulthidden="setdefaulthidden"></ColumnItemList>

            </div>

            <div v-if="search">
                <v-card-subtitle style="border-bottom: 2px solid silver; color: #9d9d9d;">SEARCH RESULTS</v-card-subtitle>

                <ColumnItemList :items="search_results"
                                :ReadOnly="ReadOnly"
                                :columnLookup="column_lookup"
                                :column_default_visibility="column_default_visibility"
                                :showHelp="showHelp"
                                :addColumn="addColumn"
                                :removeColumn="removeColumn"
                                :getColumnHelp="getColumnHelp"
                                @setvisible="setcolumnvisible"
                                @setdefaulthidden="setdefaulthidden"></ColumnItemList>
            </div>

        </v-card>

        <v-dialog v-model="help.visible" max-width="590">
            <v-card>
                <v-card-title class="text-h5">
                    {{ help.item ? help.item.Title : '' }}
                </v-card-title>

                <v-card-text v-html="help.item.Description">

                    <!--{{ help.item.Description }}-->
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary darken-1"
                           text
                           @click="help.visible = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Vue from "vue";
    import helps from '../../../careHelpfulFunctions.jsx';
    import utils from '@/Shared/utils.jsx';
    import common from './common.js';

    import ColumnItemList from './columnItemList.vue';
    import MetricTypeFilter from './metricTypeFilterPopup.vue';

    import { appSettings } from '@/Shared/appSettings';

    export default {
        components: {
            ColumnItemList,
            MetricTypeFilter,
        },
        props: {
            ReadOnly: null,
            isvisible: null,
            storageKey: null,
            column_list: {
                type: Array
            },
            source: null,
            settings: null,
            columnDefs: {
                type: Array
            },
            column_default_visibility: null,
            setColumnsDefaultHidden: null,
            setColumnsVisible: null,
            debugMode: null,
        },
        data: function () {
            return {
                categoryPanels: {},
                search: '',
                filter_menuopen: false,
                category_selection: [],
                categories: [
                    { text: 'Categories', divider: true },
                ],
                column_lookup: {},

                columnDataTypes: [
                    { text: 'Counts', filter: 'type', types: ['countColumn', 'floatColumn'] },
                    { text: 'Dates / Times', filter: 'type', types: ['dateColumn'] },
                    { text: 'Durations', filter: 'type', types: ['durationColumn'] },
                    { text: 'Text Values', filter: 'type', types: ['textSetColumn', 'searchableTextColumn', 'channelTypeColumn', 'directionColumn', 'userIDColumn', 'customerIDColumn', 'phoneNumColumn'] },
                    { text: 'True / False', filter: 'type', types: ['booleanColumn'] },
                ],
                columnCategories: [],
                //columnSources: [],

                columnDataTypes_selection: [],
                columnCategory_selection: [],
                columnSources_selection: [],

                anyFilters: false,

                help: {
                    visible: false,
                    item: {}
                },
            }
        },
        watch: {
            columnDataTypes_selection: function (newv) {
                this.saveColumnFilters();
            },
            columnCategory_selection: function (newv) {
                this.saveColumnFilters();
            },
            columnSources_selection: function (newv) {
                this.saveColumnFilters();
            },
        },
        created() {
            console.log(`loading ToolReportColumns _uid:${this._uid} with ${this.column_list?.length} column(s); ${this.columnDefs.length} column def(s)`);

            for (let cat of [...new Set(this.column_list.filter(a => a.ColumnUse.Filter || a.ColumnUse.Group).map(a => a.FilterCategory || a.Category))])
                this.categoryPanels[cat] = [];

            this.categories = [
                ...this.categories,
                ...this.filterCategories.map(a => ({ text: a, category: a }))
            ];
            this.category_selection = this.filterCategories.map((a, i) => i);

            this.column_lookup = helps.toLookup(this.columnDefs, 'colId');

            //------------------------ New --------------------//

            // Populate a distinct list of categories for metric filters
            this.columnCategories = [...new Set(this.column_list.filter(a => a.ColumnUse.Filter || a.ColumnUse.Group).map(a => a.FilterCategory || a.Category))]
                .filter(a => a)
                .toSorted((a, b) => common.byField(a, b))
                .map(a => ({ text: a.indexOf('#') <= 1 ? a.substr(a.indexOf('#') + 1) : a, filter: 'Category', category: a }));

            // Populate a distinct list of source names (contacts, users, etc)
            //this.columnSources = [...new Set(this.column_list.filter(a => a.ColumnUse.Filter).flatMap(a => a.Sources))]
            //    .toSorted((a, b) => common.byField(common.sourceTypes[a], common.sourceTypes[b]))
            //    .map(a => ({ text: a, filter: 'Source', source: a }));

            // Set the initial default filter settings
            const savedmetricFilters = appSettings.LocalStorage(this.storageKey + '-columnFilters-' + this.source);
            if (savedmetricFilters) {
                const asjson = JSON.parse(savedmetricFilters);
                this.columnDataTypes_selection = asjson.datatypes.map(a => this.columnDataTypes.findIndex(t => t.text == a)).filter(i => i >= 0);
                this.columnCategory_selection = asjson.categories.map(a => this.columnCategories.findIndex(t => t.text == a)).filter(i => i >= 0);
                //if (this.debugMode && this.sources)
                //    this.columnSources_selection = asjson.sources.map(a => this.columnSources.findIndex(t => t.text == a)).filter(i => i >= 0);
                //else
                //    this.columnSources_selection = this.columnSources.map((a, i) => i);

                this.anyFilters =
                    this.columnDataTypes_selection.length < this.columnDataTypes.length ||
                    this.columnCategory_selection.length < this.columnCategories.length;
                    //this.columnSources_selection.length < this.columnSources.length;
            }
            else {
                this.columnDataTypes_selection = this.columnDataTypes.map((a, i) => i);
                this.columnCategory_selection = this.columnCategories.map((a, i) => i);
                //this.columnSources_selection = this.columnSources.map((a, i) => i);
            }
        },
        computed: {
            filterCategories: function () {
                return [...new Set(this.column_list.filter(a => a.ColumnUse.Filter || a.ColumnUse.Group).map(a => a.FilterCategory || a.Category))]
                    .toSorted((a, b) => common.byField(a, b));
            },
            search_results: function () {
                const s = this.search.toLowerCase();
                return this.column_list
                    .filter(a =>
                        (a.ColumnUse.Filter || a.ColumnUse.Group) && (
                        a.ColId.toLowerCase().includes(s) ||
                        a.Title.toLowerCase().includes(s) ||
                        a.Tooltip?.toLowerCase().includes(s) ||
                        a.Description?.toLowerCase().includes(s) ||
                        a.SourceType?.toLowerCase().includes(s)
                        )
                    ).toSorted((a, b) => common.byField(a, b, 'Title'));
            },
            selected_columns: function () {
                return this.columnDefs.filter(a => a.colId && !a.hide);
            },
            all_groupsSelected: function () {
                return this.categories.filter(a => !a.divider).length == this.category_selection.length;
            },
        },
        methods: {
            saveColumnFilters() {
                const asjson = {
                    datatypes: this.columnDataTypes_selection.map(i => this.columnDataTypes[i].text),
                    categories: this.columnCategory_selection.map(i => this.columnCategories[i].text),
                    //sources: this.columnSources_selection.map(i => this.columnSources[i].text),
                };
                appSettings.LocalStorage(this.storageKey + '-columnFilters-' + this.source, JSON.stringify(asjson));
            },
            getColumnsByCategory(category) {
            //    return this.column_list.filter(a => (a.ColumnUse.Filter || a.ColumnUse.Group) && (a.FilterCategory || a.Category) == category && !a.CompoundFilter)
            //        .toSorted((a, b) => common.byField(a, b, 'Title'));
                //debugger;
                const visibletypes = this.columnDataTypes_selection.flatMap(i => this.columnDataTypes[i].types);
                //const visiblesources = this.columnSources_selection.map(i => this.columnSources[i].source);
                const cols = this.column_list
                    .filter(a => (a.ColumnUse.Filter || a.ColumnUse.Group) && (a.FilterCategory || a.Category) == category && visibletypes.includes(a.SourceType))
                    .toSorted((a, b) => common.byField(a, b, 'Title'));
                return cols;
            },
            isCategoryVisible(category) {
                const selected = this.columnCategory_selection.map(i => this.columnCategories[i].category);
                return selected.some(a => a == category);
            },
            setPanelState(cat, value) {
                console.log(`setPanelState(cat:${cat},value:${JSON.stringify(value)}); ${typeof value}`);

                const arr = this.categoryPanels[cat];
                arr.splice(0, arr.length);

                for (let i of value)
                    arr.push(i);

                console.log(`setPanelState(cat:${cat}) arr: ${JSON.stringify(arr)}; ${typeof value}`);
            },
            filterCategoryPrefix(cat) {
                const idx = cat.indexOf('#');
                return idx >= 0 && idx < 3 ? cat.substring(idx + 1) : cat;
            },

            setdefaulthidden(col, hidden) {
                this.setColumnsDefaultHidden(col.ColId, hidden);
            },
            setcolumnvisible(col, value) {
                this.setColumnsVisible([col.ColId], value);
            },
            showHelp(col) {
                this.help.item = col;
                this.help.visible = true;
            },
            addColumn(col) {

            },
            removeColumn(col) {

            },
            getColumnHelp(col) {
                return col.Tooltip || col.Title || 'No title set';
            },
        }
    }
</script>

<style scoped>
</style>