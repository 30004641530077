<template>
    <v-app style="height:100%; overflow:hidden;" v-if="show">
        <dialog-listener></dialog-listener>
        <action-service></action-service>
        <start-application :route="currentRoute" :query="queryString" :path="pathname"  @start-application-created="createServices"></start-application>
    </v-app>
</template>

<script>
    import DialogListener from './Application/dialogListener.jsx';
    import StartApplication from './Application/startApplication.jsx';
    import ActionService from './Application/components/ActionService.jsx';
    import '@mdi/font/css/materialdesignicons.css';
    import './JavaScript/totalcareicons/v1/totalcareicons.css';
    import './JavaScript/font-awesome/v4.7.0/css/care-font-awesome.min.css';
    import './Application/components/css/ToolTip.css';
    import ApiService from '@/Services/api';
    import TokenService from '@/Services/token';
    import WebSocketService from '@/Services/webSocketService';
    import CareService from '@/Services/careService';
    import { appSettings, careTokenSettings } from '@/Shared/appSettings';
    import PlantUML from '@/Services/plantUml';
    import EventCoordinator from '@/Services/serverEventCoordinator';
    import TokenEventListener from '@/Services/tokenEventListenerService.jsx';
    import localStorageSyncService from '@/Services/localStorageSyncService';
    import Vue from 'vue';
    import vuetify from '@/plugins/vuetify';
    import utils from './Shared/utils.jsx';

    import 'ag-grid-community/styles/ag-grid.css';
    import 'ag-grid-community/styles/ag-theme-alpine.css';

    import { LicenseManager } from "ag-grid-charts-enterprise";
    LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-062258}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CCPro_Solutions_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{CCPro}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{CCPro}_need_to_be_licensed___{CCPro}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{24_June_2025}____[v3]_[0102]_MTc1MDcxOTYwMDAwMA==84de721c7d6c62654098653b0a858f5e");

    /*
        This application is not compatible with node version 18 or later. To remedy, you can run this in the Vue folder:
        npm install node@16.0.0 --save-dev
    */

    export default {
        name: 'App',
        data: () => ({
            currentRoute: window.location.hash,
            queryString: window.location.search,
            pathname: window.location.pathname,
            show: false
        }),

        components: {
            DialogListener,
            StartApplication,
            ActionService
        },
        async created() {

            var vuePortalOrigin = window.location.origin;


            if (vuePortalOrigin.includes('localportal')) {
                // For a fully local band, use a DNS entry called localportal.localhost
                // The default is port 8080 which comes from the local node server, change to 80 to get the kestral web server instead
                vuePortalOrigin = 'http://localportal.localhost:80';
                //vuePortalOrigin = 'http://localportal.localhost:80';
            }
            else if (vuePortalOrigin.includes('localhost')) {
                //needed if you want to run mvc locally
                vuePortalOrigin = 'http://vueportal.localhost:5000'; 

                //used if you're not running mvc locally.  use the same domain as the white lable settings, but do portal not api.
                vuePortalOrigin = 'https://portal.callcorplab.com';
            }

            if (window?.careTokenSettings?.info?.access_token && window?.careTokenSettings?.info?.refresh_token)
                await loadJavaScriptWithAccessToken(`${vuePortalOrigin}/ConfigurationScripts/config.js`,                
                    window?.careTokenSettings?.info?.access_token,                
                    window?.careTokenSettings?.info?.refresh_token)
            else
                await loadFile(`${vuePortalOrigin}/ConfigurationScripts/config.js`, 'js');

            await loadFile(`${vuePortalOrigin}/ConfigurationScriptsImages/configimages.js?hash=${encodeURIComponent(tmpCareAppSettings.WhiteLabel.ImageHash)}`, 'js');

            // For local development only ------------------------------------ //
            if (window.location.hostname.includes('localportal')) {
                //tmpCareAppSettings.WhiteLabel.ApiUrl = 'http://localportal.localhost:81';
                //tmpCareAppSettings.WhiteLabel.ApiUrlBandTemplate = 'http://localportal.localhost:81';
            }
            else if (window.location.hostname.includes('localhost')) {
                tmpCareAppSettings.WhiteLabel.ApiUrl = 'https://api.callcorplab.com';
                tmpCareAppSettings.WhiteLabel.ApiUrlBandTemplate = 'https://api-{bandId}.callcorplab.com';


                //tmpCareAppSettings.WhiteLabel.ApiUrl = 'https://api.localhost';
                //tmpCareAppSettings.WhiteLabel.ApiUrlBandTemplate = 'https://api.localhost';

                //tmpCareAppSettings.WhiteLabel.ApiUrl = 'http://api.localhost:5004';
                //tmpCareAppSettings.WhiteLabel.ApiUrlBandTemplate = 'http://api.localhost:5004';

                //tmpCareAppSettings.WhiteLabel.ApiUrl = 'http://api-dev1.gp1-dev1-app07:5004';
                //tmpCareAppSettings.WhiteLabel.ApiUrlBandTemplate = 'http://api-dev1.gp1-dev1-app07:5004';
            }
            // --------------------------------------------------------------- //

            Object.assign(appSettings, tmpCareAppSettings);

            vuetify.importWhiteLabelSettings(appSettings.WhiteLabel);
            if(appSettings.WhiteLabel.Css) {
                /* Create style document */
                var css = document.createElement('style');
            
                if (css.styleSheet)
                    css.styleSheet.cssText = appSettings.WhiteLabel.Css;
                else
                    css.appendChild(document.createTextNode(appSettings.WhiteLabel.Css));
                
                /* Append style to the tag name */
                document.getElementsByTagName("head")[0].appendChild(css);
            }

            var instanceGuid = sessionStorage.getItem("InstanceGuid");
            if (instanceGuid)
                Vue.set(appSettings, 'InstanceGuid', instanceGuid);
            else {
                instanceGuid = appSettings.InstanceGuid || utils.generateUUID();
                TokenService.SessionStorageOrMemory(null, "InstanceGuid", instanceGuid);
                Vue.set(appSettings, 'InstanceGuid', instanceGuid);
            }

            Object.assign(careTokenSettings, tmpCareTokenSettings);

            await localStorageSyncService.initializeLocalStorageSync(appSettings.WhiteLabel.LoginUrl);

            this.show = true;
        },
        destroyed() {
            ApiService.destroyed();
            TokenService.destroyed();
            WebSocketService.destroyed();
            PlantUML.destroyed();
            EventCoordinator.destroyed();
            TokenEventListener.destroyed();
        },
        methods: {
            async createServices() {
                ApiService.created();
                TokenService.created();

                await CareService.created();

                EventCoordinator.created();

                await WebSocketService.created();
                
                PlantUML.created();               
                TokenEventListener.created();
            }
        }
    };
</script>


<style>
    @import "~vue-snotify/styles/material.css";
/*
    @import "~ag-grid-community/dist/styles/ag-grid.css";
    @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
*/
    @import "~splitpanes/dist/splitpanes.css";
    @import "~jointjs/dist/joint.css";

    html, body {
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: auto !important;
    }

    pre {
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 1rem;
    }

    /* -- Override the default display for a cell in ag-grid to fix centering issue for icons/buttons  -- */
    /* -- Side effect: auto row height no longer works - used in the public module list grid (6/21/24) -- */
    .ag-cell {
        display: flex;
        position: absolute;
        white-space: nowrap;
        height: 100%;
        overflow: hidden;
    }

    /* -- Override the default capitalization on tab labels -- */
    .v-tab {
        text-transform: none !important;
    }

    /* -- Override the tabs background color by unsetting it -- */
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: unset;
    }

    .navigation.theme--light.v-btn {
        color: var(--v-white-base);
    }

    /* USER DEFINED AREAS */
    .mainBodyAlertNotification {
        position: absolute;
        right: -100%;
        top: 72px;
        min-width: 333px;
        min-height: 60px;
        border-radius: 4px;
        transition: all 1s, transform 1s;
    }
        .mainBodyAlertNotification.notify {
            right: 20px;
            transition: all 1s, transform 1s;
        }

    /* -- Custom scrollbar for textarea - but the resize handle looks very bad this way, but the default is even worse (vuetify issue) -- */
    textarea::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        /*border-radius: 8px;*/
        border-radius: 0;
        background-color: #F5F5F5;
    }

    textarea::-webkit-scrollbar {
        width: 14px;
        background-color: #F5F5F5;
        cursor: pointer;
    }

    textarea::-webkit-scrollbar-thumb {
        /*border-radius: 8px;*/
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #999;
        cursor: pointer;
    }

    /* -- Custom scrollbar for dynamic menus -- */
    .c-DynamicMenuList::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        /*border-radius: 4px;*/
        border-radius: 0;
        background-color: #F5F5F5;
    }

    .c-DynamicMenuList::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
        cursor: pointer;
    }

    .c-DynamicMenuList::-webkit-scrollbar-thumb {
        /*border-radius: 4px;*/
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
        cursor: pointer;
    }

    /* -- Custom scrollbar for navigation drawer -- */
    .v-navigation-drawer__content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
        border-radius: 3px;
        background-color: #F5F5F5;
    }

    .v-navigation-drawer__content::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
        cursor: pointer;
    }

    .v-navigation-drawer__content::-webkit-scrollbar-thumb {
        /*border-radius: 3px;*/
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        /*background-color: #2C92FF;*/
        background-color: #ccc;
        cursor: pointer;
    }

    .property-grid > .v-input{
        font-size: 14px !important;
    }

    /*        .property-grid .input-group--text-field {
            min-height: 20px !important;
            padding: 2px !important;
        }

            .property-grid .input-group--text-field .input-group__input {
                max-height: 20px !important;
                min-height: 20px !important;
                padding: 0 !important;
            }*/
    /*       .property-grid .input-group--text-field .input-group__input input {
                    font-size: small !important;
                }

        .property-grid .input-group .input-group__input .input-group__selections .input-group__selections__comma, .input-group--select__autocomplete {
            font-size: small !important;
        }*/
    /*        .property-grid .btn {
            margin: 3px 1px;
            min-width: 0;
            height: auto;
            padding: 0;
        }*/
    /* CallCorp overrides to change row height in the data grid */
    .dx-datagrid .dx-row {
        padding: 0px;
    }

        .dx-datagrid .dx-row > td {
            padding: 0px;
        }

    /* CallCorp overrides for property grid input fields */
    .property-grid > .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: 24px;
    }

    .property-grid > .v-text-field.v-text-field--outlined.v-input--dense > .v-input__control {
        min-height: 24px;
    }


    .treeview-node {
        cursor: default;
        white-space: nowrap;
    }

        .treeview-node.selected {
            font-weight: bold;
        }

        .treeview-node.hastype {
            color: black;
        }

        .treeview-node.hasnotype {
            color: gray;
        }

        .treeview-node.highlight {
            color: red;
        }

    .treeview-expand-collapse {
        cursor: pointer;
        font-size: 12px;
    }

    .debug-refresh-icon {
        position: absolute;
        /*top: 0;
        left: 0;*/
        z-index: 2;
        font-size: medium;
    }

    .debug-form-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    .designer-container {
        /*padding: 3px;*/
        position: relative;
        z-index: 2; /* new - 10/27/2021 (working on Accordion) */
    }

    .designer-form {
        position: relative;
    }

    .designer-button {
        position: relative;
    }

    .designer-container-hovering {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.0;
        border: 2px solid;
        border-color: red;
        background: rgba(0,0,0,0.1);
        box-sizing: border-box;
        /*z-index: 30;*/
        z-index: 2; /* was 1 - 10/27/2021 (working on Accordion) */
    }

    .designer-container-hovering__selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.0;
        border: 2px solid;
        border-color: red;
        background: rgba(0,0,0,0.1);
        box-sizing: border-box;
    }

    .designer-hsmstate {
        position: relative;
        z-index: 30;
    }

    .designer-hsmstate-hovering {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.0;
        border: 2px solid;
        border-color: mediumpurple;
        background: rgba(255,255,0,0.2);
        box-sizing: border-box;
        border-radius: 5px;
        z-index: 30;
    }

    .designer-user-control-hovering {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.0;
        border: 2px solid;
        border-color: red;
        background: rgba(255,65,65,0.2);
        box-sizing: border-box;
        z-index: 31;
    }

    .designer-container-selected-clickthrough {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        border: 2px solid;
        border-color: red;
        background: rgba(0,0,0,0.25);
        box-sizing: border-box;
        z-index: 30;
        pointer-events: none;
    }

    .designer-container-selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        border: 2px solid;
        border-color: red;
        background: rgba(0,0,0,0.25);
        box-sizing: border-box;
        pointer-events: none;
        z-index: 30;
    }

    .designer-container-selected-clickableitem {
        z-index: 35;
    }

    .designer-hsm-selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        border: 2px solid;
        border-color: red;
        background: rgba(255,255,255,0.25);
        box-sizing: border-box;
        pointer-events: none;
        z-index: 31;
    }

    .designer-hsmstate-selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        border: 2px solid;
        border-color: mediumpurple;
        background: rgba(255,255,0,0.2);
        box-sizing: border-box;
        border-radius: 5px;
        pointer-events: none;
        z-index: 31;
    }

    .designer-user-control-selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        border: 2px solid;
        border-color: red;
        background: rgba(255,65,65,0.3);
        box-sizing: border-box;
        z-index: 31;
    }

    .designer-container-hovering:hover {
        opacity: 0.5;
    }

    .designer-user-control-hovering:hover {
        opacity: 0.5;
    }

    .designer-hsmstate-hovering:hover {
        opacity: 0.5;
    }

    /*****************************************************************/
    .design-options {
        position: absolute;
        opacity: 0.5;
        color: blueviolet;
        font-size: smaller;
        cursor: pointer;
        z-index: 32;
    }

        .design-options:hover {
            text-decoration: underline;
        }

    .design-clickable {
        position: absolute;
        cursor: pointer;
        z-index: 5;
    }

        .design-clickable:hover {
            text-decoration: underline;
        }

    .CareIcon {
        display: inline-block;
        text-align: center;
        width: 1.28571429em; /* Same as fa-fw */
    }

        .CareIcon .CareIconCaption {
            font-size: .3em;
            display: block;
            /*max-width: 6em;*/
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    .resize-observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        pointer-events: none;
        display: block;
        overflow: hidden;
        opacity: 0;
    }

    #header {
        background-color: navy !important;
        color: white;
        text-align: center;
        padding: 5px;
    }

    #nav {
        overflow-y: scroll;
        line-height: 30px;
        background-color: silver !important;
        height: 80%;
        width: 300px;
        float: left;
        padding: 5px;
    }

    #auth {
        line-height: 20px;
        height: 80%;
        width: 180px;
        float: right;
        padding: 5px;
        font-size: small;
    }

    #section {
        width: 50%;
        float: left;
        padding: 10px;
    }

    #footer {
        background-color: navy !important;
        color: white;
        clear: both;
        text-align: center;
        padding: 5px;
    }

    .login_input {
        font-size: small;
    }

    .active_context {
        background-color: yellow;
    }

    .user {
        color: blue;
        cursor: pointer;
    }

    .context {
        color: maroon;
        cursor: pointer;
    }

    .simple_link {
        cursor: pointer;
    }

    table#tableFieldPicker {
        width: 100%;
        background-color: #f3f3f3;
        border-spacing: 2px;
        vertical-align: top;
    }

        table#tableFieldPicker tr:nth-child(even) {
            background-color: #eee;
            padding: 2px;
            vertical-align: top;
        }

        table#tableFieldPicker tr:nth-child(odd) {
            background-color: #fff;
            padding: 2px;
            vertical-align: top;
        }

        table#tableFieldPicker td {
            padding: 2px;
            vertical-align: top;
        }

        table#tableFieldPicker th {
            color: white;
            background-color: black;
        }

    .warning-blinking {
        animation: blinkingText 0.8s infinite;
    }

    @keyframes blinkingText {
        0% {
            color: yellow;
        }

        49% {
            color: red;
        }

        50% {
            color: red;
        }

        59% {
            color: red;
        }

        100% {
            color: yellow;
        }
    }

    .v-fade {
        display: inherit !important; /* override v-show display: none */
        transition: opacity 0.5s;
    }

        .v-fade[style*="display: none;"] {
            opacity: 0;
            pointer-events: none; /* disable user interaction */
            user-select: none; /* disable user selection */
        }

    /*.sform-fieldset-group-item {
        transition: all 1s;
        display: inline-block;
    }

    .sform-fieldset-group-enter, .sform-fieldset-group-leave-to {
        opacity: 0;*/
    /*transform: translateX(30px);*/
    /*}

    .sform-fieldset-group-leave-active {
        position: absolute;
    }*/

    /* Used for schema form field transitions */
    .component-fade-enter-active {
        transition: opacity 1.0s ease;
    }

    .component-fade-leave-active {
        transition: opacity .1s ease;
    }

    .component-fade-enter, .component-fade-leave-to {
        opacity: 0;
    }

    .fieldset-list-item {
        transition: all 1s;
        display: inline-block;
        margin-right: 10px;
    }

    .fieldset-list-move {
        transition: transform 1s;
    }

    .fieldset-list-enter-active, .fieldset-list-leave-active {
        transition: all 1s;
    }

    .fieldset-list-enter, .fieldset-list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        /*transform: translateY(30px);*/
    }

    .fieldset-list-complete-leave-active {
        position: absolute;
    }


    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(10px);
        opacity: 0;
    }


    /* This is an admittedly brittle solution, but until another
            better solution can be found, this is the only way to
            enable proper vertical fill with scrolling in all Vuetify
            containers. In particular, a v-tabs-items with v-tab-item
            inserts a div with this class in between those we have
            control over, and without the flex and overflow settings,
            the child windows will not fill and give proper scrollbars.
            -- 5/16/2021, David P. --
        */
    .v-window__container {
        height: inherit;
        position: relative;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        display: flex; /*ADDED By CallCorp*/
        flex-direction: column; /*ADDED By CallCorp*/
        flex-grow: 1; /*ADDED By CallCorp*/
        overflow: auto; /*ADDED By CallCorp*/
    }

    .v-window-item--active { /*ADDED by CallCorp*/
        display: flex;
        flex-direction: column; /* Added recently -- New Porting Request layout wrong */
        flex-grow: 1;
        overflow: auto;
    }

    /* Vuetify does not have classes for justify and align evenly */
    .justify-space-evenly {
        justify-content: space-evenly !important;
    }

    /*  This could be a bug with flex. Vuetify by default adds a box after
        the icon that scales to 1.3, which means it is larger than its container.
        Flex does not recognize this additional size, so it causes boxes to overflow
        -- 5/25/2021, Benton --
    */
    .v-icon.v-icon::after {
        transform: scale(1);
    }

    .v-input .v-label, .v-input .v-messages {
        pointer-events: all;
    }

    .v-input__control label {
        display: inherit !important;
    }

    /****/
    .v-application .caption {
        font-size: unset !important;
    }

    .dialog-size {
        min-height: 300px;
        min-width: 300px;
        transition:inherit !important;
    }

    .dialog-resize {
        min-height: 300px;
        min-width: 300px;
        resize: both;
        transition: none !important;
    }

    /*
        Alters vue-functional-calendar
        Added the font override
    */
    .vfc-content {
        margin: 0 20px;
        z-index: 100;
        font-family: "Roboto", sans-serif; /*ADDED by CallCorp*/
    }

    .vfc-noshadow-callcorp > .vfc-main-container {
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
    }

    .vfc-week .vfc-day span.vfc-span-day.vfc-today {
        background-color: var(--v-darkGreyIcon-base);
    }

    .vfc-week .vfc-day span.vfc-span-day.vfc-marked {
        background-color: var(--v-primaryActive-base);
    }

    .vfc-week .vfc-day span.vfc-span-day.vfc-marked:before {
        background-color: var(--v-primary-base);
    }

    .vfc-week .vfc-day .vfc-base-start, .vfc-week .vfc-day .vfc-base-end {
        background-color: var(--v-primary-base);
    }

    .feedback-item {
        font-weight: 600;
        font-size: 16px;
        opacity: '80%';
    }

    .text-normal {
        text-transform: unset !important;
    }

    .right-aligned-cell {
        text-align: right;
        flex-direction: column-reverse;
    }

    div.bordered-list-item div.v-list-item {
        border: 1px solid var(--v-lightGreyBorder-base);
    }

    div.bordered-list-item div.v-list-item div.v-list-item__title {
        font-size: 14px;
        font-weight: 400;
    }

    .v-application .warning .v-card__text {
        color: var(--v-lightGrey-base) !important;
        background-color: var(--v-warning-base) !important;
    }

    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 8px;
    }

    /** Override vuetify's tree-view selected colors */
    .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:before, .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover:before {
        opacity: 0 !important;
    }

    .v-treeview .v-treeview-node__root.v-treeview-node--active, .v-treeview .v-treeview-node__root:hover {
        background-color: var(--v-cyan-base);
    }

    /** override .v-chip sizes */
    .v-chip.v-size--default {
        height: 25px !important;
    }

    /** override alpine checkbox styles */
    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--v-primary-base, #2c92ff)
    }

    
    /** override for v-card array background color styles */
    .card-array {
        background-color: #fafafa !important;
        border-color: black;
        border-color: var(--v-darkGreyIcon-base) !important;
    }
    .card-array .v-card{
        background-color: #fafafa !important;
    } 
    .card-array fieldset{
        background-color: white !important;
    }
    .card-array hr {
        border-color: var(--v-darkGreyIcon-base) !important;
    }
    
    /** Used to correct alignment issues when placed alongside other */
    .input-file fieldset {
        max-height: 41px !important;
    }

    /** mdi spin and pulse animations */
    .mdi-animate-spin {
        -webkit-animation: spin 2s infinite linear;
        animation: spin 2s infinite linear;
    }
    
    .mdi-animate-pulse {
        -webkit-animation: pulse 1s infinite steps(10);
        animation: pulse 1s infinite steps(10);
    }

    @keyframes pulse {
        from { opacity: 0; }
        50% { opacity: 1; }
        to { opacity: 0; }
    }

    /* override box shadow in v-expansion panel */
    .v-expansion-panel:before {
        box-shadow: none;
    }
</style>

